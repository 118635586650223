<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="{{propertyLogo}}" alt="Koko Water Park Logo" (click)="onAction()" height="45">
    </a>
  </div>
  
</div>

<div class="header-container">
  <nb-action
    class="mat-ripple user-action ng-star-inserted"
    *nbIsGranted="['view', 'user']"
  >
    <nb-user
      class="size-medium shape-round context-menu-host ng-star-inserted"
      [nbContextMenu]="userMenu"
      [onlyPicture]="userPictureOnly"
      [name]="
        user
      "
    >
    </nb-user>
  </nb-action>
</div>
